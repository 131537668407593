import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { shape } from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

const propTypes = {
  intl: shape().isRequired,
  location: shape().isRequired,
}

const Thanks = ({ intl, location }) => {
  const { locale } = intl
  const slugs = {
    fi: "/thanks",
    en: "/thanks",
  }
  return (
    <Layout slugs={slugs} currentPath={`/${locale}/thanks`}>
      <SEO title="Thanks for your interest" location={location.pathname} />
      <h1>{intl.formatMessage({ id: "thank_you" })}</h1>
      <p>{intl.formatMessage({ id: "form_thankyou_text" })}</p>

      <form
        name="maintenance_form"
        method="post"
        action="en/thanks"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        style={{ display: "none" }}
      >
        <input type="hidden" name="form-name" value="maintenance_form" />
        <p hidden="">
          <input name="bot-field" />
        </p>
        <input
          type="text"
          placeholder="Company*:"
          name="company"
          aria-label="Company"
        />
        <input
          type="text"
          placeholder="Business ID*:"
          name="business-id"
          aria-label="Business ID"
        />
        <input
          type="email"
          placeholder="Email*:"
          name="email"
          aria-label="Email"
        />
        <input
          type="text"
          placeholder="Address*:"
          name="billing-address"
          aria-label="Address"
        />
        <input
          type="text"
          placeholder="Postal code*:"
          name="billing-zip"
          aria-label="Postal code"
        />
        <input
          type="text"
          placeholder="City*:"
          name="billing-city"
          aria-label="City"
        />
        <input
          type="text"
          placeholder="Maintenance location name*:"
          name="maintenance-location-name"
          aria-label="Maintenance location name"
        />
        <input
          type="text"
          placeholder="Address*:"
          name="maintenance-location-address"
          aria-label="Address"
        />
        <input
          type="text"
          placeholder="Postal code*:"
          name="maintenance-location-zip"
          aria-label="Postal code"
        />
        <input
          type="text"
          placeholder="City*:"
          name="maintenance-location-city"
          aria-label="City"
        />
        <input
          type="text"
          placeholder="Opening hours*:"
          name="maintenance-location-hours"
          aria-label="Opening hours"
        />
        <input
          type="text"
          placeholder="Phone*:"
          name="maintenance-location-phone"
          aria-label="Phone"
        />
        <input
          type="text"
          placeholder="Brand and model*:"
          name="maintenance-target-brand-and-model"
          aria-label="Brand and model"
        />
        <input
          type="text"
          placeholder="PNC number:"
          name="maintenance-target-pnc"
          aria-label="PNC number"
        />
        <input
          type="text"
          placeholder="SER number:"
          name="maintenance-target-ser"
          aria-label="SER number"
        />
        <input
          type="text"
          placeholder="Purchase date:"
          name="maintenance-target-purchase-date"
          aria-label="Purchase date"
        />
        <input
          type="text"
          placeholder="Reference number:"
          name="maintenance-target-reference-nr"
          aria-label="Reference number"
        />
        <input
          type="checkbox"
          id="maintenance_target_warranty"
          name="maintenance-target-warranty"
          aria-label="Warranty is valid"
        />
        <textarea
          placeholder="Description*:"
          name="message"
          aria-label="Description"
        ></textarea>
        <button type="submit">Send</button>
      </form>
    </Layout>
  )
}

Thanks.propTypes = propTypes

export default injectIntl(Thanks)
